.nav {
  position: sticky;
  top: 0;
  width: 100%;
  font-weight: bold;
  z-index: 1;
  --height: 8rem;
}

.nav::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: rgba(235, 181, 205, 0.75);
  backdrop-filter: blur(2.4rem);
  z-index: -1;
  opacity: 0;
  transition: opacity 100ms linear;
}

.navSticky::before {
  opacity: 1;
}

.nav > ul {
  display: grid;
  grid-template-columns: 1fr 1fr;
  list-style: none;
  margin: 0;
  padding: 0;
  height: var(--height);
  border-bottom: 0.2rem solid var(--text);
}

.nav > ul > li {
  display: flex;
  border-left: 0.1rem solid var(--text);
  border-right: 0.1rem solid var(--text);
}

.nav > ul > li:first-child {
  border-left: 0;
}

.nav > ul > li:last-child {
  border-right: 0;
}

.nav > ul > li > a,
.nav > ul > li > button {
  flex: 1;
  display: flex;
  justify-content: space-between;
  border: 0;
  padding: 1.6rem;
  text-decoration: none;
  color: var(--text);
  font-weight: inherit;
  background: none;
  align-items: baseline;
  appearance: none;
}

.navMenuOpen > ul > li > a,
.navMenuOpen > ul > li > button {
  background-color: var(--text);
  color: var(--background);
}

.logo {
  position: relative;
  background-color: transparent !important;
}

.logo::before {
  content: '';
  position: absolute;
  inset: 0;
  pointer-events: none;
  background-size: cover;
  z-index: -1;
  background-image: var(--backgroundImage);
  background-position: 0 40%;
  opacity: 0;
}

.navMenuOpen .logo::before {
  opacity: 1;
}

.overlay {
  position: fixed;
  top: 12rem;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 1;
  max-width: var(--container-width);
}

@media (min-width: 768px) {
  .overlay {
    top: 13.8rem;
    border: 0.2rem solid var(--text);
    border-bottom-width: 0;
    border-top-width: 0;
  }
}

.overlaySticky {
  top: 8rem;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: rgba(235, 181, 205, 0.75);
  backdrop-filter: blur(2.4rem);
  max-height: 100%;
  overflow-y: scroll;
}

.menu > li {
  border-bottom: 0.2rem solid var(--text);
}

.menu > li > a {
  flex: 1;
  display: flex;
  text-decoration: none;
  color: inherit;
  height: 9.6rem;
  padding: 1.6rem;
  font-weight: bold;
}
