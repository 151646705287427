.heroText {
  color: var(--primary);
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr 1fr;
  align-items: baseline;
  font-size: clamp(1rem, 21vw, 9rem);
  letter-spacing: 0.01em;
  overflow-x: clip;
  line-height: 1;
  margin: 0 0 1.6rem;
  column-gap: 1.6rem;
}

.xs {
  font-size: 0.5em;
}

.heroText span:nth-child(3) {
  text-align: right;
  grid-column: 1/-1;
}

.heroText span:nth-child(4) {
  grid-column: 1/-1;
}

@media (min-width: 768px) {
  .heroText {
    grid-template-columns: min-content min-content min-content;
    font-size: 9.7rem;
  }

  .heroText span:nth-child(3) {
    text-align: unset;
    grid-column: auto;
  }

  .heroText span:nth-child(4) {
    grid-column: auto;
  }
}
