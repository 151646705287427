.container {
  margin: 0 auto;
  width: 100%;
  max-width: var(--container-width);
}

@media (min-width: 768px) {
  .wrapper {
    padding: 1.6rem;
  }

  .container {
    border: 0.2rem solid var(--text);
  }
}
