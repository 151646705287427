.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 51.2rem;
  margin: 0 auto;
  padding: 2.4rem;
  min-height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  gap: 1.6rem;
}

.form > label {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.form input {
  font-size: 1.8rem;
  border-radius: 0;
  height: 4rem;
  border: 0;
  padding: 0.8rem;
  border: 0.2rem solid var(--text);
  color: var(--text);
}

.form button {
  background-color: var(--text);
  color: var(--background);
  font-weight: bold;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  padding: 0;
}
