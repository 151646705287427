.toastmasters {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

@media (min-width: 512px) {
  .toastmasters {
    flex-direction: row;
  }
}

.toastmasters > div {
  flex-basis: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
