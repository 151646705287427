.container {
  position: relative;
  overflow: hidden;
}

.placeholder {
  position: absolute;
  inset: 0;
  background-size: cover;
  z-index: 1;
  pointer-events: none;
  opacity: 1;
  will-change: opacity;
  transition: opacity 200ms linear;
}

.placeholder::after {
  content: '';
  position: absolute;
  inset: 0;
  backdrop-filter: blur(1rem);
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
}
