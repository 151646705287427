@font-face {
  font-family: 'Clearface';
  src: url('./assets/fonts/ClearfaceStd-Regular.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Clearface';
  src: url('./assets/fonts/ClearfaceStd-Bold.woff2') format('woff2');
  font-weight: 700;
}

@font-face {
  font-family: 'Clearface';
  src: url('./assets/fonts/ClearfaceStd-Black.woff2') format('woff2');
  font-weight: 900;
}

@font-face {
  font-family: 'Sneak';
  src: url('./assets/fonts/Sneak-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Sneak';
  src: url('./assets/fonts/Sneak-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Sneak';
  src: url('./assets/fonts/Sneak-Bold.woff') format('woff');
  font-weight: 700;
}
