.marquee {
  --gap: 1.6rem;
  display: flex;
  overflow: hidden;
  user-select: none;
  height: 40px;
  background: var(--secondary);
  border-bottom: 2px solid var(--text);
  align-items: center;
  gap: var(--gap);
  font-size: 1.6rem;
  font-weight: bold;
}

.marquee:hover > * {
  animation-play-state: paused;
}

.marquee > * {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  min-width: 100%;
  gap: var(--gap);
  animation: scroll 10s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}
