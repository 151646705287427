html {
  --background: rgb(235, 181, 205);
  --primary: rgb(201, 74, 46);
  --secondary: rgb(248, 218, 61);
  --text: rgb(0, 0, 0);

  --font-heading: Clearface, serif;
  --font-body: Sneak, sans-serif;

  --noise: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.25' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");

  --mask-edge-scalloped: radial-gradient(
        farthest-side,
        #000 97%,
        rgba(0, 0, 0, 0)
      )
      0 0 / 20px 20px round,
    linear-gradient(#000 0 0) 50% / calc(100% - 20px) calc(100% - 20px)
      no-repeat;

  --container-width: 76.8rem;

  font-size: 62.5%;
  box-sizing: border-box;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: Sneak, sans-serif;
  color: var(--text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  font-size: 1.8rem;
  background-image: var(--noise);
  background-size: 32rem;
  background-position: center;
  animation: background 1s infinite;
  animation-timing-function: steps(3);
  min-height: 100%;
}

body::after {
  content: '';
  background-color: var(--background);
  position: fixed;
  inset: 0;
  mix-blend-mode: lighten;
}

@keyframes background {
  0% {
    background-position: top left;
  }
  50% {
    background-position: center;
  }
  100% {
    background-position: top right;
  }
}

#root {
  position: relative;
  /* display: contents; */
  z-index: 1;
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
  font-weight: bold;
}

p {
  line-height: 1.2;
  margin-block-start: 1.6rem;
  margin-block-end: 1.6rem;
}

a {
  color: inherit;
}
