.rsvp main {
  padding: 0;
}

.iframeWrapper {
  height: calc(100vh - 12rem);
  overflow: hidden;
}

.iframe {
  border: 0;
}
