.main {
  display: flex;
  flex-direction: column;
  padding: 2.4rem;
}

.subHeading {
  margin: 0 0 2.4rem 0;
  font-size: 2.4rem;
  font-family: var(--font-body);
  /* font-weight: normal; */
}

.photoWrapper {
  width: calc(100% + 2.4rem);
  margin-right: -2.4rem;
}

.cta {
  background-color: var(--text);
  color: var(--background);
  appearance: none;
  border: 0;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: center;
  font-size: 2.4rem;
  width: 100%;
  height: 8rem;
  justify-content: center;
  font-weight: bold;
  text-decoration: none;
  mask: var(--mask-edge-scalloped);
}
